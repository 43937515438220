import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"
import { motion } from "framer-motion"
import theme from "./theme"

interface Props {
  opaque?: boolean
  hidden?: boolean
}

export const Overlay = styled(motion.main)(
  {
    display: "flex",
    zIndex: "1",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    alignItems: "center",
    flexFlow: "column nowrap",
    color: "white",
    padding: 30,
    textAlign: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: `opacity 1200ms ease-in`,
  },
  (props: Props) => ({
    backgroundColor: props.opaque ? "black" : "rgba(0,0,0,0.8)",
    opacity: props.hidden ? 0 : 1,
  })
)

/* export const GlitchText = ({ children, className, as, ...rest }) => {
 *   const Elem = as || "span";
 *   return (
 *     <Elem
 *       className={`glitch-2 ${className}`}
 *       data-text={children}
 *       {...rest}
 *     >{children}</Elem>
 *   );
 * };
 *  */
const primaryColor = "#fff"
const secondaryColor = "black"
const steps = 50

const glitchAnim1 = keyframes({
  "0%": { clip: "rect(8px, 1620px, 81px, 0)" },
  "5.8823529412%": { clip: "rect(93px, 1620px, 10px, 0)" },
  "11.7647058824%": { clip: "rect(10px, 1620px, 84px, 0)" },
  "17.6470588235%": { clip: "rect(99px, 1620px, 9px, 0)" },
  "23.5294117647%": { clip: "rect(96px, 1620px, 104px, 0)" },
  "29.4117647059%": { clip: "rect(12px, 1620px, 75px, 0)" },
  "35.2941176471%": { clip: "rect(9px, 1620px, 56px, 0)" },
  "41.1764705882%": { clip: "rect(36px, 1620px, 47px, 0)" },
  "47.0588235294%": { clip: "rect(98px, 1620px, 23px, 0)" },
  "52.9411764706%": { clip: "rect(59px, 1620px, 59px, 0)" },
  "58.8235294118%": { clip: "rect(50px, 1620px, 26px, 0)" },
  "64.7058823529%": { clip: "rect(111px, 1620px, 91px, 0)" },
  "70.5882352941%": { clip: "rect(103px, 1620px, 49px, 0)" },
  "76.4705882353%": { clip: "rect(6px, 1620px, 68px, 0)" },
  "82.3529411765%": { clip: "rect(6px, 1620px, 16px, 0)" },
  "88.2352941176%": { clip: "rect(30px, 1620px, 28px, 0)" },
  "94.1176470588%": { clip: "rect(78px, 1620px, 73px, 0)" },
  "100%": { clip: "rect(78px, 1620px, 99px, 0)" },
})
const glitchAnim2 = keyframes({
  "0%": { clip: "rect(29px, 1620px, 90px, 0)" },
  "5.8823529412%": { clip: "rect(19px, 1620px, 11px, 0)" },
  "11.7647058824%": { clip: "rect(60px, 1620px, 106px, 0)" },
  "17.6470588235%": { clip: "rect(5px, 1620px, 103px, 0)" },
  "23.5294117647%": { clip: "rect(83px, 1620px, 6px, 0)" },
  "29.4117647059%": { clip: "rect(99px, 1620px, 69px, 0)" },
  "35.2941176471%": { clip: "rect(23px, 1620px, 29px, 0)" },
  "41.1764705882%": { clip: "rect(50px, 1620px, 54px, 0)" },
  "47.0588235294%": { clip: "rect(75px, 1620px, 100px, 0)" },
  "52.9411764706%": { clip: "rect(77px, 1620px, 89px, 0)" },
  "58.8235294118%": { clip: "rect(55px, 1620px, 63px, 0)" },
  "64.7058823529%": { clip: "rect(22px, 1620px, 26px, 0)" },
  "70.5882352941%": { clip: "rect(15px, 1620px, 42px, 0)" },
  "76.4705882353%": { clip: "rect(114px, 1620px, 11px, 0)" },
  "82.3529411765%": { clip: "rect(89px, 1620px, 68px, 0)" },
  "88.2352941176%": { clip: "rect(100px, 1620px, 106px, 0)" },
  "94.1176470588%": { clip: "rect(97px, 1620px, 72px, 0)" },
  "100%": { clip: "rect(41px, 1620px, 56px, 0)" },
})

export const GlitchText = styled.span(
  {
    position: "relative",
    color: primaryColor,
    userSelect: "none",
    textTransform: "uppercase",

    "&::before, &::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      clip: "rect(0,0,0,0)",
    },
    "&::after": {
      left: -1,
      textShadow: "-2px 0 #b4fba8",
      animation: `${glitchAnim1} 2s infinite linear alternate-reverse`,
    },
    "&::before": {
      left: 1,
      textShadow: "1px 0 #b4fba8",
      animation: `${glitchAnim2} 3s infinite linear alternate-reverse`,
    },
  },
  (props) => ({
    "&::before, &::after": {
      content: `"${props.children.toString()}"`,
    },
  })
)
